import React, { FC, useEffect } from "react";
import { WijmoGrid } from "@app/components";
import { fmtMsg, GSSchoolAction, TYPES } from "@app/util";
import { TagManagerLocale } from "@app/locales/localeid";
import { Modal } from "antd-min";
import { useState } from "react";
import { ITagManagerService, TagModel, TagUsageModel } from "@app/service/tag-manager";
import { GLGlobal, MatIcon } from "gl-commonui";
import { useService } from "@app/hooks";
import "./tag-manager-usage.less";

const { Grid, Column } = WijmoGrid;

interface TagManagerUsageProps {
	getRef?: React.MutableRefObject<TagManagerUsageRef>;
	reloadTags: () => void;
}
export interface TagManagerUsageRef {
	open: (tagId: TagModel) => void;
	close: () => void;
}

export const TagManagerUsage: FC<TagManagerUsageProps> = (props) => {
	const { getRef, reloadTags } = props;
	const [tag, setTag] = useState<TagModel>({} as any);
	const [usages, setUsages] = useState<TagUsageModel[]>();
	const [loading, setLoading] = useState<boolean>(false);

	const [isChanged, setIsChanged] = useState<boolean>(false);

	const tagManagerService = useService<ITagManagerService>(TYPES.ITagManagerService);
	const hasManagePermission = GLGlobal.isActionValid(GSSchoolAction.ListRegionSchoolTagManage);

	useEffect(() => {
		passRef();
	}, []);

	useEffect(() => {
		loadUsage();
	}, [tag]);

	const passRef = () => {
		if (getRef) {
			getRef.current = {
				open,
				close,
			};
		}
	};

	const open = (tagId: TagModel) => {
		setTag(tagId);
	};

	const close = () => {
		if (isChanged) {
			reloadTags();
			setIsChanged(false);
		}
		setTag({} as any);
	};

	const remove = (usageId: string) => {
		setLoading(true);
		tagManagerService
			.deleteUsage(tag.id, usageId)
			.then(() => {
				const newUsages = usages.filter((iteratedUsage) => iteratedUsage.id !== usageId);
				setUsages(newUsages);
				setLoading(false);
				setIsChanged(true);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const loadUsage = () => {
		if (!tag.id) {
			return;
		}

		setLoading(true);
		tagManagerService
			.getUsage(tag.id, tag.tagEntityTypeId)
			.then((usage) => {
				setUsages(usage);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<Modal visible={!!tag.id} onCancel={close} title={fmtMsg({ id: TagManagerLocale.UsagePopupTitle }, { tagName: tag.name })} footer={false}>
			<Grid itemsSource={usages} loading={loading} stickyHeaders={false}>
				<Column header={fmtMsg({ id: TagManagerLocale.UsagePopupGridEntityLabel })} binding="name"></Column>
				{hasManagePermission && (
					<Column
						header={fmtMsg({ id: TagManagerLocale.UsagePopupGridRemoveLabel })}
						align="center"
						render={(value, usage: TagUsageModel) => {
							return (
								<a className="tag-manager__remove" onClick={() => remove(usage.id)}>
									<MatIcon type="close" />
								</a>
							);
						}}
					></Column>
				)}
			</Grid>
		</Modal>
	);
};
