import { Action, Actions, Container, SecondaryTitle, WijmoGrid } from "@app/components";
import { useActionCreator, useService } from "@app/hooks";
import { useEffectAfterFirst } from "@app/hooks/useEffectAfterFirst";
import { TagManagerLocale } from "@app/locales/localeid";
import { ITagManagerService, TagModel, TagTypeModel } from "@app/service/tag-manager";
import { TYPES } from "@app/service/types";
import { StateType } from "@app/states";
import { fetchTagTypes, setTagListUpdated } from "@app/states/tag-manager/tag-manager";
import { fmtMsg, GSSchoolAction } from "@app/util";
import { Select } from "antd-min";
import { connect, GLGlobal, GLRouteComponentProps, MatIcon, withRouter } from "gl-commonui";
import { Modal } from "gl-commonui/lib/antd-min";
import React, { FC, useEffect, useRef, useState } from "react";
import { TagManagerAddEdit, TagManagerAddRef } from "./components/tag-manager-add/tag-manager-add";
import { TagManagerUsage, TagManagerUsageRef } from "./components/tag-manager-usage/tag-manager-usage";
import "./tag-manager.less";

const { Grid, Column } = WijmoGrid;

interface TagManagerPageProps extends GLRouteComponentProps {
	tagTypes: TagTypeModel[];
	fetchTagTypes: () => void;
}
const TagManagerComponent: FC<TagManagerPageProps> = (props) => {
	const { tagTypes, fetchTagTypes, match } = props;
	const {
		params: { regionId },
	} = match;
	const defaultTypeValue = (tagTypes[0] && tagTypes[0].id) || null;

	const [currentType, setCurrentType] = useState<number>(defaultTypeValue);
	const [tags, setTags] = useState<TagModel[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const addPopupRef = useRef<TagManagerAddRef>();
	const usagePopupRef = useRef<TagManagerUsageRef>();
	const tagManagerService = useService<ITagManagerService>(TYPES.ITagManagerService);
	const hasManagePermission = GLGlobal.isActionValid(GSSchoolAction.ListRegionSchoolTagManage);

	const setTagListUpdatedAction = useActionCreator(setTagListUpdated);

	useEffect(() => {
		loadTagTypes();
	}, []);

	useEffectAfterFirst(() => {
		setTypeDefaultValue();
	}, [tagTypes]);

	useEffect(() => {
		loadTags();
	}, [currentType]);

	const loadTagTypes = () => {
		if (!tagTypes.length) {
			setLoading(true);
			fetchTagTypes();
		}
	};
	const loadTags = () => {
		if (currentType) {
			setLoading(true);
			tagManagerService
				.getTags(regionId, currentType)
				.then((tags) => {
					setTags(tags);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	};

	const setTypeDefaultValue = () => {
		if (!currentType && defaultTypeValue) {
			setCurrentType(defaultTypeValue);
		}
	};

	const openAddEditPopup = (tag?: TagModel) => {
		addPopupRef.current.open(regionId, currentType, tag);
	};
	const openUsagePopup = (tag: TagModel) => {
		usagePopupRef.current.open(tag);
	};

	const onSelectEntity = (value: number) => {
		setCurrentType(value);
	};

	const removeTag = (tag: TagModel) => {
		Modal.confirm({
			title: fmtMsg({ id: TagManagerLocale.RemovePopupTitle }),
			content: fmtMsg({ id: TagManagerLocale.RemovePopupText }, { tagName: tag.name }),
			cancelText: fmtMsg({ id: TagManagerLocale.RemovePopupCancel }),
			okText: fmtMsg({ id: TagManagerLocale.RemovePopupDelete }),
			onOk: () => {
				return tagManagerService.deleteTag(tag.id).then(() => {
					const newTags = tags.filter((iteratedTag) => iteratedTag.id !== tag.id);
					setTags(newTags);
					setTagListUpdatedAction(true);
				});
			},
		});
	};

	const renderHead = () => {
		return (
			<React.Fragment>
				<SecondaryTitle title={TagManagerLocale.PageTitle} />
				<div className="tag-manager__head">
					<Select value={currentType} onChange={onSelectEntity}>
						{tagTypes.map((type) => (
							<Select.Option value={type.id} key={type.id}>
								{type.name}
							</Select.Option>
						))}
					</Select>
					<Actions noHeight>
						<Action
							textLocaleId={TagManagerLocale.AddLinkText}
							onClick={() => openAddEditPopup()}
							materialIcon="add"
							disabled={!currentType}
							action={GSSchoolAction.ListRegionSchoolTagManage}
						/>
					</Actions>
				</div>
			</React.Fragment>
		);
	};

	const renderContent = () => {
		return (
			<div className="tag-manager">
				<Grid itemsSource={tags} loading={loading}>
					<Column
						binding="name"
						header={fmtMsg({ id: TagManagerLocale.GridTagLabel })}
						render={(value, tag: TagModel) => {
							if (!hasManagePermission) {
								return value;
							}
							return (
								<a className="tag-manager__link" onClick={() => openAddEditPopup(tag)}>
									{value}
								</a>
							);
						}}
					/>
					<Column
						binding="usage"
						header={fmtMsg({ id: TagManagerLocale.GridUsageLabel })}
						render={(value, tag: TagModel) => {
							if (value === 0) {
								return value;
							}
							return (
								<a className="tag-manager__link" onClick={() => openUsagePopup(tag)}>
									{value}
								</a>
							);
						}}
					/>
					{hasManagePermission && (
						<Column
							header={fmtMsg({ id: TagManagerLocale.GridRemoveLabel })}
							align="center"
							render={(value, tag: TagModel) => {
								return (
									<a className="tag-manager__remove" onClick={() => removeTag(tag)}>
										<MatIcon type="close" />
									</a>
								);
							}}
						/>
					)}
				</Grid>
			</div>
		);
	};

	const renderAddPopup = () => {
		return <TagManagerAddEdit getRef={addPopupRef} tagTypes={tagTypes} tags={tags} reloadTags={loadTags} />;
	};
	const renderUsagePopup = () => {
		return <TagManagerUsage getRef={usagePopupRef} reloadTags={loadTags} />;
	};

	return (
		<Container>
			{renderHead()}
			{renderContent()}
			{hasManagePermission && renderAddPopup()}
			{renderUsagePopup()}
		</Container>
	);
};

export const TagManagerPage = withRouter(
	connect(
		(state: StateType) => ({
			tagTypes: state.tagManager.types,
		}),
		{ fetchTagTypes },
	)(TagManagerComponent),
);
