import { useActionCreator, useService } from "@app/hooks";
import { TagManagerLocale } from "@app/locales/localeid";
import { FormItem } from "@app/page/cims/component/cims-consts";
import { ITagManagerService, TagCreateRequestModel, TagListModel, TagModel, TagTypeModel } from "@app/service/tag-manager";
import { setTagListUpdated } from "@app/states/tag-manager/tag-manager";
import { fmtMsg, TYPES } from "@app/util";
import { Input, Modal } from "antd-min";
import { GLForm, GLFormComponentProps } from "gl-commonui";
import { Select } from "gl-commonui/lib/antd-min";
import React, { FC, useEffect, useState } from "react";
import "./tag-manager-add.less";

interface TagManagerAddProps extends GLFormComponentProps {
	tagTypes: TagTypeModel[];
	tags: TagModel[];
	getRef?: React.MutableRefObject<TagManagerAddRef>;
	reloadTags: () => void;
}
export interface TagManagerAddRef {
	open: (regionId: string, tagTypeId: number, tag?: TagModel) => void;
	close: () => void;
}

const TagManagerAddEditComponent: FC<TagManagerAddProps> = (props) => {
	const { form, tagTypes, getRef, reloadTags, tags: tagsFromProps } = props;
	const { getFieldDecorator, setFieldsValue, resetFields, getFieldsValue } = form;

	const [visible, setVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [tags, setTags] = useState<Set<string>>();
	const [selectedTag, setSelectedTag] = useState<TagModel>(null);

	const tagManagerService = useService<ITagManagerService>(TYPES.ITagManagerService);
	const setTagListUpdatedAction = useActionCreator(setTagListUpdated);

	const isEditMode = !!selectedTag;

	useEffect(() => {
		passRef();
	}, []);

	useEffect(() => {
		setTagsInState(tagsFromProps);
	}, [tagsFromProps]);

	const passRef = () => {
		if (getRef) {
			getRef.current = {
				open,
				close,
			};
		}
	};

	const setTagsInState = (tagsCollection: TagModel[] | TagListModel[]) => {
		const tagSet = new Set<string>();
		tagsCollection.forEach((tag) => {
			tagSet.add(tag.name);
		});
		setTags(tagSet);
	};

	const open = (regionId: string, tagTypeId: number, tag?: TagModel) => {
		if (tag) {
			// Edit mode
			setSelectedTag(tag);
			setFieldsValue({ regionId, tagEntityTypeId: tag.tagEntityTypeId, name: tag.name });
		} else {
			// Create mode
			setSelectedTag(null);
			setFieldsValue({ regionId, tagEntityTypeId: tagTypeId });
		}

		setVisible(true);
	};

	const close = () => {
		setVisible(false);
		setTimeout(resetFields, 100);
	};

	const onEntityChange = (entityId: number) => {
		const { regionId } = getFieldsValue();
		tagManagerService.getTagsList(regionId, entityId).then((tags) => {
			setTagsInState(tags);
		});
	};

	const setFormFields = () => {
		getFieldDecorator("regionId", {
			rules: [
				{
					required: true,
				},
			],
		});
	};

	const tagNameUniqueValidator = (_, tagName: string, callback: Function) => {
		if (isEditMode && tagName === selectedTag.name) {
			return callback();
		}
		if (tags.has(tagName)) {
			return callback(fmtMsg({ id: TagManagerLocale.AddPopupTagUniqueMessage }));
		}
		return callback();
	};

	const submitHandler = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			form.validateFields((err, values: TagCreateRequestModel) => {
				if (err) {
					reject();
				} else {
					let createOrEditCall: Promise<void>;
					if (isEditMode) {
						createOrEditCall = tagManagerService.updateTag({ ...values, id: selectedTag.id });
					} else {
						createOrEditCall = tagManagerService.createTag(values);
					}
					createOrEditCall
						.then((res) => {
							resolve(res);
							setTagListUpdatedAction(true);
						})
						.catch(reject);
				}
			});
		});
	};

	const onOk = () => {
		setLoading(true);
		submitHandler()
			.then(() => {
				reloadTags();
				close();
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	setFormFields();
    let multiLangRegx = new RegExp("^[a-z0-9-\u0E00-\u0E7F\u00C0-\u00FF" +
        "\u0600-\u06FF\u3131-\uD79D\u3131-\uD79D\u4e00-" +
        "\u9fa5\u3000-\u303f\u3040-\u309f\u30a0-\u30ff" +
        "\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf\u0430-\u044f]*$")

	return (
		<Modal
			visible={visible}
			onCancel={close}
			title={fmtMsg({ id: TagManagerLocale.AddPopupTitle })}
			okButtonProps={{ loading }}
			okText={fmtMsg({ id: TagManagerLocale.AddPopupSave })}
			cancelText={fmtMsg({ id: TagManagerLocale.AddPopupCancel })}
			onOk={onOk}
			className="tag-manager-add-modal"
		>
			<div className="tag-manager-add">
				<GLForm form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<FormItem label={fmtMsg({ id: TagManagerLocale.AddPopupEntityLabel })}>
						{getFieldDecorator("tagEntityTypeId", {
							rules: [
								{
									required: true,
								},
							],
						})(
							<Select className="tag-manager-add__select" onChange={onEntityChange} disabled={isEditMode}>
								{tagTypes.map((type) => (
									<Select.Option value={type.id} key={type.id}>
										{type.name}
									</Select.Option>
								))}
							</Select>,
						)}
					</FormItem>
					<FormItem label={fmtMsg({ id: TagManagerLocale.AddPopupTagLabel })} className="tag-manager-add__control-last">
						{getFieldDecorator("name", {
							rules: [
								{
									validator: tagNameUniqueValidator,
								},
								{
									required: true,
									whitespace: false,
									message: fmtMsg({ id: TagManagerLocale.AddPopupTagRequiredMessage }),
								},
								{
									max: 50,
									min: 3,
									message: fmtMsg({ id: TagManagerLocale.AddPopupTagLengthMessage }, { min: 3, max: 50 }),
								},
								{
                                    pattern: multiLangRegx,
									message: fmtMsg({ id: TagManagerLocale.AddPopupTagPatternMessage }),
								},
							],
						})(<Input />)}
					</FormItem>
				</GLForm>
			</div>
		</Modal>
	);
};

export const TagManagerAddEdit = GLForm.create({ name: "tag-manager-add" })(TagManagerAddEditComponent);
